import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import Loader from "./Components/Loader/Loader";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClinetId } from "./Constants";
import { Toaster } from "react-hot-toast";

import Home from "./home/Home";
import Login from "./Components/Login/Login";
// import { test_stream } from "./apiCall";
// import { useEffect } from "react";
// import axios from "axios";

function App() {
    const [{ isLoading }] = useStateValue();

    // useEffect(() => {
    //     const getData = async () => {
    //         let my_text = ""

    //         const resp = await axios.post(
    //             'http://localhost:8000/api/chat/get-response/34',
    //             {
    //               query: "What is stremming?"
    //             },
    //             {
    //               headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTYzOTMxMTcsInN1YiI6InByaXRtYW52YXJAZ21haWwuY29tIn0.of2Jqwf2YiOt-PYSPazlbCCGemnH_STxceA-1YlMlQA"}`
    //               },
    //               onDownloadProgress: (progressEvent) => {
    //                 console.log(progressEvent)
    //                 console.log("response is", progressEvent.event.target.response)
    //                 my_text += progressEvent.event.target.response;
    //               },
    //             }
    //           );
          
              
    //         console.log(resp)
    //         console.log(my_text == resp.data)
    //     };
    //     getData()
    // }, []);

    return (
        <div className='App'>
            {isLoading && <Loader />}
            <GoogleOAuthProvider clientId={googleClinetId}>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path='/'
                            element={
                                <>
                                    <Home/>
                                </>
                            }
                        />
                        <Route
                            path='/login'
                            element={
                                <>
                                    <Login/>
                                </>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </GoogleOAuthProvider>
            <Toaster />
        </div>
    );
}

export default App;
