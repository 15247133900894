import React, { useState } from "react";

import "./temperatureSelection.scss";
import { ChangeResponseStyle } from "../../apiCall";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { ReactComponent as CloseIcon } from "../../Assets/icons/close.svg";
import { ReactComponent as ResponseIcon } from "../../Assets/icons/response-type.svg";

const TemperatureSelection = ({
    id,
    selectedResponseStyle,
    setSelectedResponseStyle,
    showSelection,
    setShowSelection,
}) => {
    const [{ isLoggedIn }] = useStateValue();
    const navigateTo = useNavigate();

    const changeResponseStyle = async (responseStyle) => {
        if (!isLoggedIn) {
            navigateTo("/login");
            return;
        }
        const re = await ChangeResponseStyle(id, responseStyle);
        console.log("CREATE CHAT", re);

        if (re?.response?.data?.detail) {
            toast.error(re?.response?.data?.detail);
        } else if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                setSelectedResponseStyle(responseStyle);
                if(window.innerWidth <= 1150){
                    setShowSelection(false)
                }
            }
        }
    };
    return (
        <>
            <div
                className={`temperature-selection ${
                    showSelection ? "" : "hide-selection"
                }`}>
                <CloseIcon onClick={() => setShowSelection((pre) => !pre)} />
                <div className='head'>
                    <p className='title bold'>Select Response Style</p>
                    <p className='desc'>
                        Responstyle can be explained like how creative answer
                        you will get.
                    </p>
                </div>
                <div className='options'>
                    <button
                        className={`${
                            selectedResponseStyle === "FOCUSED"
                                ? "primary"
                                : "secondary"
                        }`}
                        onClick={() => changeResponseStyle("FOCUSED")}>
                        Focused
                    </button>
                    <button
                        className={`${
                            selectedResponseStyle === "PRECISE"
                                ? "primary"
                                : "secondary"
                        }`}
                        onClick={() => changeResponseStyle("PRECISE")}>
                        Precise
                    </button>
                    <button
                        className={`${
                            selectedResponseStyle === "BALANCED"
                                ? "primary"
                                : "secondary"
                        }`}
                        onClick={() => changeResponseStyle("BALANCED")}>
                        Balanced
                    </button>
                    <button
                        className={`${
                            selectedResponseStyle === "FLEXIBLE"
                                ? "primary"
                                : "secondary"
                        }`}
                        onClick={() => changeResponseStyle("FLEXIBLE")}>
                        Flexible
                    </button>
                    <button
                        className={`${
                            selectedResponseStyle === "CREATIVE"
                                ? "primary"
                                : "secondary"
                        }`}
                        onClick={() => changeResponseStyle("CREATIVE")}>
                        Creative
                    </button>
                </div>
            </div>
            <div className='response-type-icons'>
                <ResponseIcon onClick={() => setShowSelection((pre) => !pre)} />
            </div>
        </>
    );
};

export default TemperatureSelection;
