import React from "react";
import Modal from "react-bootstrap/Modal";

import { useStateValue } from "./StateProvider";
import "./modal.scss";
import Login from "./Components/Login/Login";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";

export function LoginModal(props) {
    const [{ loginModalShow }, dispatch] = useStateValue();
    const setLoginModalShow = () => {
        dispatch({
            type: "SET_LOGIN_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={loginModalShow}
            onHide={() => setLoginModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <Login
                    formType={props.loginFormType}
                    setFormType={props.setFormType}
                />
            </Modal.Body>
        </Modal>
    );
}

export function ForgotPasswordModal(props) {
    const [{ forgotPasswordModalShow }, dispatch] = useStateValue();
    const setForgotPasswordModalShow = () => {
        dispatch({
            type: "SET_FORGOT_PASSWORD_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={forgotPasswordModalShow}
            onHide={() => setForgotPasswordModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <ForgotPassword />
            </Modal.Body>
        </Modal>
    );
}
