import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";

import { ReactComponent as Send } from "../../Assets/icons/send.svg";
import { ReactComponent as Like } from "../../Assets/icons/thumbs-up.svg";
import { ReactComponent as DisLike } from "../../Assets/icons/thumbs-down.svg";
import Celebrate from "../../Assets/gif/celebrate.gif";

import "./chatWindow.scss";
import { GenerateChatName, UpdateLikeStatus } from "../../apiCall";
import toast from "react-hot-toast";
import axios from "axios";
import { domainName } from "../../Constants";

const sampleQuestions = ["How can aapkaCFO help me with marketing?", "Give me some marketing plans to improve my bussiness.", "What is marketing?", "How marketing effects my bussiness?"]

const ChatWindow = ({
    chatHistory,
    setChatHistory,
    lastText,
    setLastText,
    activeChat,
    activeChatObj,
    setChats,
    showSideBar,
    setShowSideBar,
}) => {
    const chatWindowRef = useRef();
    const textBoxRef = useRef();
    const [message, setMessage] = useState("");
    const [currentText, setCurrentText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [messageBoxHeight, setMessageBoxHeight] = useState(3);
    const [showAnimation, setShowAnimation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const updateLikeStatus = async (id, status) => {
        if (!id) {
            return;
        }
        const re = await UpdateLikeStatus(id, status);
        console.log("Update Like Status", re);

        if (re?.response?.data?.detail) {
            toast.error(re?.response?.data?.detail);
        } else if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                console.log(chatHistory);
                setChatHistory((pre) => {
                    console.log(pre, "PRE");
                    return pre.map((msg) => {
                        console.log(msg);
                        console.log(msg?.id, id, msg?.id === id);
                        if (msg?.id === id) {
                            return { ...msg, like_status: status };
                        } else {
                            return msg;
                        }
                    });
                });
                if (lastText?.id === id) {
                    setLastText((pre) => {
                        return { ...pre, like_status: status };
                    });
                }

                if (status === "Liked") {
                    setShowAnimation(true);
                }
            }
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (setShowAnimation) {
                setShowAnimation(false);
            }
        }, 1500);
        return () => clearTimeout(timeout);
    }, [showAnimation]);

    useEffect(() => {
        chatWindowRef?.current?.scrollTo(
            0,
            chatWindowRef?.current?.scrollHeight
        );
    }, [chatHistory?.length, currentText]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!lastText) {
                setCurrentText("");
                setCurrentIndex(0);
                clearInterval(interval);
            } else {
                if (currentIndex < lastText?.message?.split(" ").length) {
                    setCurrentText(
                        currentText +
                            " " +
                            lastText?.message?.split(" ")[currentIndex]
                    );
                    setCurrentIndex(currentIndex + 1);
                } else {
                    clearInterval(interval);
                }
            }
        }, 80);

        return () => clearInterval(interval);
    }, [lastText, currentIndex, currentText]);

    useEffect(() => {
        // setMessageBoxHeight(message?.split("\n").length * 2);
        // textBoxRef.current.innerText = message.split("").reverse().join("");

        if(!message){
            textBoxRef.current.innerText = ""
            textBoxRef.current.innerHTML = ""
            setMessage()
        }
    }, [message]);

    useEffect(() => {
        setLastText();
        setCurrentIndex(0);
        setCurrentText("");
    }, [setLastText, activeChat]);

    const handleSend = async (my_message) => {
        console.log(message)
        if ((!my_message && !message.trimEnd().trimStart()) || isLoading) {
            return;
        }
        if (!activeChat) {
            return;
        }
        if(!my_message){
            my_message = message;
        }
        setChatHistory((pre) => [
            ...pre,
            lastText,
            { message: my_message, user: "human" },
        ]);
        setLastText();
        setIsLoading(true);
        console.log("sending");

        setMessage();
        textBoxRef.current.innerHTML = ""
        textBoxRef.current.innerText = ""
        const resp = await axios.post(
            `${domainName}/api/chat/get-response/${activeChat}`,
            {
                query: my_message,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${
                        localStorage.getItem("token") ||
                        sessionStorage.getItem("token")
                    }`,
                },
                onDownloadProgress: (progressEvent) => {
                    console.log(progressEvent);
                    console.log(
                        "response is",
                        progressEvent.event.target.response
                    );
                    console.log("OK");

                    // if(chatHistory[chatHistory.length - 1].user === 'human'){
                    //     setChatHistory(pre => [...pre, {message: progressEvent.event.target.response, user: 'AI'}])
                    // }else{
                    setLastText({
                        message: progressEvent.event.target.response.split(
                            "MY_ID_OF_MESSAGE_IS"
                        )[0],
                        user: "AI",
                        id:
                            progressEvent.event.target.response.split(
                                "MY_ID_OF_MESSAGE_IS"
                            ).length > 1
                                ? parseInt(
                                      progressEvent.event.target.response.split(
                                          "MY_ID_OF_MESSAGE_IS"
                                      )[1]
                                  )
                                : undefined,
                    });
                    // setChatHistory(pre => pre.map((chat, indx) => {
                    //     if(indx === pre.length - 1 && chat?.user === 'AI'){
                    //         return {message: progressEvent.event.target.response.split("MY_ID_OF_MESSAGE_IS")[0], user: 'AI', "id": progressEvent.event.target.response.split("MY_ID_OF_MESSAGE_IS").length > 1 ? parseInt(progressEvent.event.target.response.split("MY_ID_OF_MESSAGE_IS")[1]) : undefined}
                    //     }else{
                    //         return chat
                    //     }
                    // }))
                    // }
                },
            }
        );

        console.log(resp);

        setIsLoading(false);
    };

    useEffect(() => {
        const getData = async () => {
            const re = await GenerateChatName(activeChat);
            console.log("GENERATED CHAT NAME", re.data?.value, re.status);

            if (re?.response?.data?.detail) {
                toast.error(re?.response?.data?.detail);
            } else if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page");
            } else {
                if (re.data?.status[0].Error === "True") {
                    toast.error(re.data?.status[0].ResponseMessage);
                } else {
                    setChats((pre) =>
                        pre.map((chat) => {
                            if (chat.id === activeChat) {
                                return { ...chat, chat_name: re.data.value };
                            } else {
                                return chat;
                            }
                        })
                    );
                }
            }
        };
        console.log(activeChatObj);
        console.log(chatHistory);
        console.log(
            chatHistory,
            chatHistory?.length > 2,
            activeChatObj,
            !activeChatObj[0]?.chat_name,
            activeChatObj?.length > 0,
            chatHistory?.length > 0,
            chatHistory?.length > 0 &&
                activeChatObj?.length > 0 &&
                !activeChatObj[0]?.chat_name
        );
        if (
            chatHistory?.length > 2 &&
            isLoading === false &&
            activeChatObj?.length > 0 &&
            !activeChatObj[0]?.chat_name
        ) {
            getData();
        }
    }, [chatHistory, activeChatObj, activeChat, isLoading, setChats]);

    return (
        <div className='chat-window'>
            <div className='messages scroll' ref={chatWindowRef}>
                {chatHistory?.length > 0 ? (
                    <>
                        {chatHistory.map((msg, indx) =>
                            msg?.message ? (
                                <div
                                    className='message-container'
                                    key={"message" + indx}>
                                    <span
                                        className={`message ${
                                            msg?.user === "human"
                                                ? "sender"
                                                : msg?.user === "AI"
                                                ? "reciver"
                                                : "default"
                                        }`}>
                                        <span className='content'>
                                            <ReactMarkdown>
                                                {msg?.message}
                                            </ReactMarkdown>
                                        </span>
                                    </span>
                                    <span
                                        className={`icons ${msg?.like_status}`}>
                                        <Like
                                            className='like'
                                            onClick={() =>
                                                updateLikeStatus(
                                                    msg?.id,
                                                    msg?.like_status === "Liked"
                                                        ? "None"
                                                        : "Liked"
                                                )
                                            }
                                        />
                                        <DisLike
                                            className='dislike'
                                            onClick={() =>
                                                updateLikeStatus(
                                                    msg?.id,
                                                    msg?.like_status ===
                                                        "DisLiked"
                                                        ? "None"
                                                        : "DisLiked"
                                                )
                                            }
                                        />
                                    </span>
                                </div>
                            ) : (
                                <></>
                            )
                        )}
                        <div className='message-container'>
                            {currentText ? (
                                <>
                                    <span className={`message ${"reciver"}`}>
                                        <span className='content'>
                                            <ReactMarkdown>
                                                {currentText}
                                            </ReactMarkdown>
                                        </span>
                                    </span>
                                    <span
                                        className={`icons ${lastText?.like_status}`}>
                                        <Like
                                            className='like'
                                            onClick={() =>
                                                updateLikeStatus(
                                                    lastText?.id,
                                                    lastText?.like_status ===
                                                        "Liked"
                                                        ? "None"
                                                        : "Liked"
                                                )
                                            }
                                        />
                                        <DisLike
                                            className='dislike'
                                            onClick={() =>
                                                updateLikeStatus(
                                                    lastText?.id,
                                                    lastText?.like_status ===
                                                        "DisLiked"
                                                        ? "None"
                                                        : "DisLiked"
                                                )
                                            }
                                        />
                                    </span>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="sample-questions">
                        <div className="sample-questions-container">

                        {sampleQuestions?.map((que, indx) => <span className="question" key={"que-"+indx} onClick={() => handleSend(que)}>{que}</span>)}
                        </div>
                    </div>
                )}
                {isLoading && (
                    <div className='loading-cards'>
                        <div className='loading-card slider-item'></div>
                        <div className='loading-card loading-card-1 slider-item'></div>
                        <div className='loading-card loading-card-2 slider-item'></div>
                    </div>
                )}
            </div>
            {showAnimation && (
                <img src={Celebrate} className='animation' alt='' />
            )}
            <div className='send-message'>
                <span className='message-text'>
                    <div
                        contenteditable='true'
                        className={`my-text-box ${message?.trim() ? "" : "show-before"}`}
                        ref={textBoxRef}
                        onInput={(e) => setMessage(e.target.innerText.trimStart())}
                        onClick={() => console.log(textBoxRef.current.innerHTML)}
                        onKeyDown={(e) => {
                            if (
                                e.key === "Enter" &&
                                !e.shiftKey &&
                                !e.ctrlKey
                            ) {
                                handleSend();
                            }
                        }}
                        ></div>
                    {console.log(message)}
                    {/* {!message?.trim() && <span className='write-here' onClick={() => textBoxRef.current.click()}>Write Here...</span>} */}
                    <span onClick={() => handleSend()}>
                        <Send />
                    </span>
                </span>
            </div>
        </div>
    );
};

export default ChatWindow;
