import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { useStateValue } from "../../StateProvider";
import { ReactComponent as Eye } from "../../Assets/icons/eye.svg";
import { ReactComponent as ClosedEye } from "../../Assets/icons/eye-off.svg";
import { ReactComponent as Google } from "../../Assets/icons/google.svg";
// import { ReactComponent as Facebook } from "../../Assets/icons/facebook-color.svg";
// import { ReactComponent as Apple } from "../../Assets/icons/apple.svg";
import "./login.scss";
import { SignIn, SignUp, googleLogin } from "../../apiCall";
import { toast } from "react-hot-toast";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordModal } from "../../Modals";

const Login = () => {
    const [formType, setFormType] = useState("signin");

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    // const [rememberMe, setRememberMe] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const navigateTo = useNavigate();

    const [, dispatch] = useStateValue();

    // const openLoginForm = (status) => {
    //     dispatch({
    //         type: "SET_LOGIN_MODAL_SHOW",
    //         status: status,
    //     });
    // };
    const handleForgotPasswordModal = (status) => {
        navigateTo('/')
        dispatch({
            type: "SET_FORGOT_PASSWORD_MODAL_SHOW",
            status: status,
        });
    };

    const verifyGoogleUser = async (data) => {
        const re = await googleLogin(data.access_token);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                // setError("general", {type: "general", message: re.data.status[0].ResponseMessage})
                toast.error(re.data.status[0].ResponseMessage);
                return;
            } else {
                localStorage.setItem("token", re.data.access_token);
                localStorage.setItem("name", re.data.value?.name);
                localStorage.setItem("email", re.data.value?.email);

                dispatch({
                    type: "SET_USER_LOGGED_IN",
                    status: true,
                });
                toast.success("Logged in successfully!!!")
                navigateTo('/')
            }
        }
    };

    // google login
    const doGoogleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            console.log(tokenResponse, "token response");
            verifyGoogleUser(tokenResponse);
        },
        onError: (error) => console.log("error", error),
    });

    const handleFormSubmit = async (data) => {
        if (formType === "signup") {
            const re = await SignUp(data);
            console.log(re);

            if (re?.response?.data?.detail) {
                toast.error(re?.response?.data?.detail);
            } else if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    toast.error(re.data.status[0].ResponseMessage);
                    return;
                } else {
                    // if (rememberMe) {
                    localStorage.setItem("token", re.data.access_token);
                    localStorage.setItem("name", re.data.value?.name);
                    localStorage.setItem("email", re.data.value?.email);
                    // } else {
                    // sessionStorage.setItem("token", re.data.access_token);
                    // sessionStorage.setItem("name", re.data.value?.name);
                    // sessionStorage.setItem("email", re.data.value?.email);
                    // }
                    dispatch({
                        type: "SET_USER_LOGGED_IN",
                        status: true,
                    });
                    toast.success("Logged in successfully!!!")
                    navigateTo('/')
                }
            }
        } else {
            console.log(data);
            const re = await SignIn(data);
            console.log(re);

            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    // setError("general", {type: "general", message: re.data.status[0].ResponseMessage})
                    toast.error(re.data.status[0].ResponseMessage);
                    return;
                } else {
                    // if (rememberMe) {
                    localStorage.setItem("token", re.data.access_token);
                    localStorage.setItem("name", re.data.value?.name);
                    localStorage.setItem("email", re.data.value?.email);
                    // } else {
                    //     sessionStorage.setItem("token", re.data.access_token);
                    //     sessionStorage.setItem("name", re.data.value?.name);
                    //     sessionStorage.setItem("email", re.data.value?.email);
                    // }
                    dispatch({
                        type: "SET_USER_LOGGED_IN",
                        status: true,
                    });
                    toast.success("Logged in successfully!!!")
                    navigateTo('/')
                }
            }
        }
    };
    return (
        <div className='login-main'>
            <div className='heading'>
                <h2>
                    {formType === "signup"
                        ? "Create an account"
                        : "Welcome back"}
                </h2>
            </div>
            <div className='body'>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    {formType === "signup" && (
                        <div className='input-grp'>
                            <input
                                type='name'
                                placeholder='Name'
                                {...register("name", {
                                    require: true,
                                })}
                            />
                            {errors.name?.type === "required" && (
                                <p className='form-error'>Name is required.</p>
                            )}
                        </div>
                    )}
                    <div className='input-grp'>
                        <input
                            type='email'
                            placeholder='Email'
                            {...register("email", {
                                required: true,
                            })}
                        />
                        {errors.email?.type === "required" && (
                            <p className='form-error'>Email is required.</p>
                        )}
                    </div>
                    <div className='input-grp'>
                        <span className='password-input'>
                            <input
                                type={isPasswordVisible ? "text" : "password"}
                                placeholder='Password'
                                {...register("password", {
                                    required: true,
                                    pattern:
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                                })}
                            />
                            {isPasswordVisible ? (
                                <ClosedEye
                                    onClick={() => setIsPasswordVisible(false)}
                                />
                            ) : (
                                <Eye
                                    onClick={() => setIsPasswordVisible(true)}
                                />
                            )}
                        </span>
                        {errors.password?.type === "required" && (
                            <p className='form-error'>Password is required.</p>
                        )}
                        {errors.password?.type === "pattern" && (
                            <p className='form-error'>
                                Must Be Contains Uppercase, Lowercase Letters,
                                Numbers, Special Characters And Length Is
                                Greater Than 8 Character And Less Then 16
                                Character.
                            </p>
                        )}
                    </div>
                    <div className='remember-forgot'>
                        {/* <div
                            className='remember'
                            onClick={() => setRememberMe((pre) => !pre)}>
                            <span
                                className={`check-box ${
                                    rememberMe ? "checked" : ""
                                }`}>
                                {rememberMe && <Checked />}
                            </span>
                            <p>Remember me</p>
                        </div> */}
                        <p onClick={() => handleForgotPasswordModal(true)}>
                            forgot password?
                        </p>
                    </div>
                    <button className='primary' type='submit'>
                        {formType === "signup" ? "Get Started" : "Log In"}
                    </button>
                </form>
                <p className="new-user">
                    {formType === "signup" ? (
                        <p>
                            Already have an account?{" "}
                            <span
                                className='bold'
                                onClick={() => {
                                    setFormType("signin");
                                    reset();
                                }}>
                                Log In
                            </span>
                        </p>
                    ) : (
                        <p>
                            Don't have an account?{" "}
                            <span
                                className='bold'
                                onClick={() => {
                                    setFormType("signup");
                                    reset();
                                }}>
                                Sign up
                            </span>
                        </p>
                    )}
                </p>
                <div className='or'>
                    <hr />
                    <span>OR</span>
                    <hr />
                </div>
                <div className='social-login'>
                    <span onClick={doGoogleLogin}>
                        <Google />
                        Continue With Google
                    </span>
                </div>
            </div>
            <ForgotPasswordModal />
            
        </div>
    );
};

export default Login;
