import React, { useState } from "react";
import { ReactComponent as Logo } from "../../Assets/icons/logo-transparant.svg";
import { ReactComponent as TriangleIcon } from "../../Assets/icons/triangle.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import "./nav.scss";

const Nav = () => {

    const [showOptions, setShowOptions] = useState(false)

    const hideAllOptions = () => {
        console.log("HIDE")
        setShowOptions(false)
    }
    const showAllOptions = () => {
        console.log("SHOW")
        setShowOptions(true)
    }
    return (
        <>
            <div className='nav-main'>
                <div className='head'>
                    <Logo className="logo"/>
                    <div className='bot-selection'>
                        <div className='wrapper' onClick={showAllOptions}>
                            <p className='selected'>BuzzGenius</p>
                            <TriangleIcon />
                        </div>
                        {showOptions && <div className='options' onClick={hideAllOptions}>
                            <p data-tooltip-id='bot-buzzgenius' >BuzzGenius</p>
                            <ReactTooltip
                                id='bot-buzzgenius'
                                place='left'
                                variant='white'
                                content='BuzzGenius is marketing genius who is ready to attend your
                                marketing related queries.'
                                />
                            <p data-tooltip-id='bot-buzzgenius1'>BuzzGenius1</p>
                            <ReactTooltip
                                id='bot-buzzgenius1'
                                place='left'
                                variant='white'
                                content='BuzzGenius is marketing genius who is ready to attend your
                                marketing related queries.'
                                />
                            <p data-tooltip-id='bot-buzzgenius2'>BuzzGenius2</p>
                            <ReactTooltip
                                id='bot-buzzgenius2'
                                place='left'
                                variant='white'
                                content='BuzzGenius is marketing genius who is ready to attend your
                                marketing related queries.'
                                />
                        </div>}
                    </div>
                </div>
                {/* <p className='bold'>
                    BuzzGenius is marketing genius who is ready to attend your
                    marketing related queries.
                </p> */}
            {showOptions && <div className="back-drop" onClick={hideAllOptions}></div>}
            </div>
        </>
    );
};

export default Nav;
