import { api } from "./Constants";
import { request } from "./utils/axios-utils";

// ***************************************************  Create User  ****************************************************************
export async function SignUp(data) {
    return request({ url: api.signup, method: "post", data: data });
}

// ***************************************************  Login  ****************************************************************
export async function SignIn(data) {
    return request({ url: api.login, method: "post", data: data });
}
export const googleLogin = (googleToken) => {
    return request({
        url: api.google_login,
        method: "post",
        data: {
            access_token: googleToken,
        },
    });
};

// ***************************************************  forgot password  ****************************************************************
export async function ResetPassword(data) {
    return request({ url: api.forgot_password, method: "post", data: data });
}

// ***************************************************  send otp ****************************************************************
export async function SendOtp(data) {
    return request({ url: api.otp, method: "post", data: data });
}

// *************************************************** verify otp ****************************************************************
export async function VerifyOtp(data) {
    return request({ url: api.verify_otp, method: "post", data: data });
}

// *************************************************** create new chat ****************************************************************
export async function CreateChat() {
    return request({ url: api.create_chat, method: "post" });
}

// *************************************************** get chats ****************************************************************
export async function GetChats() {
    return request({ url: api.create_chat, method: "get" });
}

// *************************************************** change chat name ****************************************************************
export async function ChangeChatName(id, name) {
    return request({ url: api.create_chat + "/" + id + "/" + name, method: "patch" });
}

// *************************************************** delete chat ****************************************************************
export async function DeleteChat(id) {
    return request({ url: api.create_chat + "/" + id, method: "delete" });
}

// *************************************************** change response type ****************************************************************
export async function ChangeResponseStyle(id, responseStyle) {
    return request({ url: api.create_chat + "/response-style/" + id + "/" + responseStyle, method: "patch" });
}

// *************************************************** getMessageHistory ****************************************************************
export async function GetMessageHistory(id) {
    return request({ url: api.create_chat + "/" + id, method: "get" });
}

// *************************************************** update like status ****************************************************************
export async function UpdateLikeStatus(id, status) {
    return request({ url: api.like_status + "/" + id + "/" + status, method: "patch" });
}

// *************************************************** change chat name ****************************************************************
export async function test_stream() {
    return request({ url: "http://localhost:8000/api/chat/streaming", method: "get", });
}

// *************************************************** generate chat name ****************************************************************
export async function GenerateChatName(id) {
    return request({ url: api.generate_chat_name + "/" + id, method: "get" });
}