export const initialState = {
  isLoggedIn: (localStorage.getItem("token") || sessionStorage.getItem("token")) ? true : false,
  isLoading: false,
  loginModalShow: false,
  forgotPasswordModalShow: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_IS_LOADING":
      if (action.status) {
        document.body.style.overflow = "hidden";
        window.scrollTo(0, 0);
      } else {
        document.body.style.overflow = "auto";
      }
      return {
        ...state,
        isLoading: action.status,
      };
      case "SET_LOGIN_MODAL_SHOW":
        if(action.status === true){
            sessionStorage.setItem('last_location', window.location.pathname)
        }
        return {
            ...state,
            loginModalShow: action.status,
        };

    case "SET_FORGOT_PASSWORD_MODAL_SHOW":
        return {
            ...state,
            forgotPasswordModalShow: action.status,
        };

    case "SET_USER_LOGGED_IN":
        return {
            ...state,
            isLoggedIn: action.status,
        };


    default:
      return state;
  }
}

export default reducer;
