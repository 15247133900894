export const googleClinetId = "166799978388-mrkptojl2s27aoducs8t2sspkohhj5q8.apps.googleusercontent.com"

export const STALE_TIME = 1800000 // Data is considered fresh for 30 minutes (in milliseconds)
export const CACHE_TIME = 7200000 // Data will be cached for 2 hour (in milliseconds)

export const domainName = "https://admin.bizgenius.aapkacfo.com"
// export const domainName = "http://localhost:8000"

export const webSocketDomain = "wss://admin.bizgenius.aapkacfo.com"
// export const webSocketDomain = "ws://localhost:8000"

export const api = {
  signup: '/api/signup',
  login: '/api/login',
  send_otp: '/api/otp',
  verify_otp: '/api/verify-otp',
  forgot_password: '/api/resetPassword',
  google_login: "/api/verify/google_access_token",
  otp: "/api/otp",
  create_chat: "/api/chat",
  like_status: "/api/chat/like-status",
  generate_chat_name: "/api/chat/generate-chat-name",
};
